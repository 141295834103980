import { defineStore } from 'pinia'
import { toProperCase, getInitials, toShortName } from '../utils/profile'

// simulate waiting period between 600 and 1200 milliseconds
const enableFakeWait = false;
const fakeWait = () => {
  return Math.floor(Math.random() * (1200 - 600 + 1)) + 600;
}

// Pinia Store
export const useUserStore = defineStore({
  id: 'user',
  persist: [
    {
      paths: ['email', 'token'],
      storage: localStorage,
    },
    {
      paths: ['authError', 'profile', 'stats', 'address', 'accounts', 'policies', 'transactions', 'claims', 'savedClaims'],
      storage: sessionStorage,
    },
  ],
  state: () => ({
    // auth
    email: null,
    token: null,
    authError: null,
    // user data
    profile: {
      clientId: null,
      firstName: null,
      lastName: null,
      birthdate: null,
      lang: null,
    },
    // stats
    stats: null,
    // profile data
    address: null,
    accounts: null,
    policies: null,
    transactions: null,
    claims: null,
    savedClaims: null,
  }),
  actions: {
    clearSession() {
      // clear session data
      this.email = null
      this.token = null
      this.profile = {
        clientId: null,
        firstName: null,
        lastName: null,
        birthdate: null,
        lang: null,
      }
      this.stats = null
      this.address = null
      this.accounts = null
      this.policies = null
      this.transactions = null
      this.claims = null
      this.savedClaims = null
    },
    userProfile() {
      const fullName = `${toProperCase(this.profile.firstName)} ${toProperCase(this.profile.lastName)}`
      return {
        image: 'https://images.unsplash.com/photo-1533738363-b7f9aef128ce?q=80&w=100&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        full: fullName,
        short: toShortName(fullName),
        abbr: getInitials(fullName),
      }
    },
    async fetchProfile() {
      if(!this.profile.clientId) {
        // reminder: data, pending, status and error
        // are Vue refs and must be accessed with `.value`
        const data = await $fetch(`/api/profile?email=${this.email}`)

        if(data.status === 500) {
          console.error('DATA INTEGRITY VIOLATION DETECTED.')
          this.email = null
          return {status:false,code:500,message:'We had trouble signing you in. Please contact our client care team.'}
        }
        if(data.status === 400) {
          console.error(data.message.toUpperCase())
          this.email = null
          return {status:false,code:500,message:'We cant find an account matching this email address.'}
        }
        if(!data.client_id) {
          console.error('PROFILE DATA IS EMPTY.', JSON.stringify(data))
          this.email = null
          return {status:false,code:500,message:'We had trouble signing you in. Please contact our client care team.'}
        }

        this.profile = {
          clientId: data.client_id,
          firstName: data.first_name,
          lastName: data.last_name,
          birthdate: data.birthdate,
          lang: data.lang,
        }
      }
      return true
    },
    async fetchStats() {
      if(!this.stats) {
        const data = await $fetch('/api/stats', {
          method: 'POST',
          body: JSON.stringify({clientId: this.profile.clientId})
        })
        if(enableFakeWait) { setTimeout(() => {this.stats = data}, fakeWait()) } else { this.stats = data }
      }
      return true
    },
    async fetchAddress() {
      if(!this.address || this.address.length === 0) {
        const data = await $fetch('/api/address', {
          method: 'POST',
          body: JSON.stringify({clientId: this.profile.clientId})
        })
        this.address = data
      }
      return true
    },
    async fetchAccounts() {
      if(!this.accounts || this.accounts.length === 0) {
        const data = await $fetch('/api/accounts', {
          method: 'POST',
          body: JSON.stringify({clientId: this.profile.clientId})
        })
        if(enableFakeWait) { setTimeout(() => {this.accounts = data}, fakeWait()) } else { this.accounts = data }
      }
      return true
    },
    async fetchPolicies() {
      if(!this.policies || this.policies.length === 0) {
        const data = await $fetch('/api/policies', {
          method: 'POST',
          body: JSON.stringify({clientId: this.profile.clientId})
        })
        this.policies = data
      }
      return true
    },
    /**
     * Fetch transactions. Pass `true` to return all records. Returns 5 records by default.
     * @param {int|bool} limit optional
     */
    async fetchTransactions(limit=false) {
      if(limit || !this.transactions || this.transactions.length === 0) {
        const data = await $fetch('/api/transactions', {
          method: 'POST',
          body: JSON.stringify({clientId: this.profile.clientId, limit})
        })
        if(enableFakeWait) { setTimeout(() => {this.transactions = data}, fakeWait()) } else { this.transactions = data }
      }
      return true
    },
    async fetchClaims(purge=false) {
      if(purge===true) {
        this.claims = null
      }
      if(!this.claims || this.claims.length === 0) {
        const data = await $fetch('/api/claims', {
          method: 'POST',
          body: JSON.stringify({clientId: this.profile.clientId})
        })
        if(enableFakeWait) { setTimeout(() => {this.claims = data}, fakeWait()) } else { this.claims = data }
      }
      return true
    },
    async fetchSavedClaims(purge=false) {
      if(purge===true) {
        this.savedClaims = null
      }
      if(!this.savedClaims || this.savedClaims.length === 0) {
        const data = await $fetch('/api/claim/list', {
          method: 'POST',
          body: JSON.stringify({clientId: this.profile.clientId})
        })
        if(enableFakeWait) { setTimeout(() => {this.savedClaims = data}, fakeWait()) } else { this.savedClaims = data }
      }
      return true
    },
  }
})
