// return a name in proper case; ie: "John"
export function toProperCase(name) {
  return name.toLowerCase().replace(/(^|\s)\S/g, L => L.toUpperCase())
}

// return initials from a name; ie: "JD"
export function getInitials(name) {
  return name.split(' ').map(part => part[0].toUpperCase()).join('')
}

// return a short name; ie: "John D."
export function toShortName(name) {
  const parts = name.split(' ')
  if (parts.length > 1) {
    return `${parts[0]} ${parts[parts.length - 1][0].toUpperCase()}.`
  }
  return parts[0]
}
